import React from 'react';

import { NotesHistory } from '../../components/views/history/notesHistory/notesHistory';
import { LayoutLoggedIn } from '../../components/views/layout';

export default function() {
    return (
        <LayoutLoggedIn>
            <NotesHistory
                Action={0}
                startDate={'1 Jan 2020'}
            />
        </LayoutLoggedIn>
    );
}